import * as React from "react"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Index = ({ location }) => {
  const siteTitle = "HSpotlight"

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Homepage" />
      <Bio />
    </Layout>
  )
}

export default Index
